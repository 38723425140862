/**
 * Type: ページ
 * What: CHATTERBOX EXPRESS Fukuoka
 */
import React, { useEffect } from 'react';

// Data Props Template
const Template = () => {
  // 初回DOM
  useEffect(() => {
    window.location.href = "https://e-zofukuoka.com/the-foodhall/";
  });
  return true;
};

export default Template;
